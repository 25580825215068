import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import UserTokenList from "./Components/UserTokenList";
import GenerateTokenScreen from "./pages/GenerateTokenScreen";
import UserDashboard from "./Components/UserDashboard/UserDashboard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { io } from "socket.io-client";
import { API_URL } from "./constants/constants";
import { ChakraProvider } from "@chakra-ui/react";
import PermanentTokenScreen from "./pages/PermanentTokenScreen";

export const socket = io(API_URL, {
  transports: ["websocket", "polling", "flashsocket"],
});

function App() {
  return (
    <ChakraProvider>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/userToken" element={<UserTokenList />} />
            <Route path="/generate/:id" element={<GenerateTokenScreen />} />
            <Route
              path="/:id/permanent/:token"
              element={<PermanentTokenScreen />}
            />
            <Route path="/userDashboard" element={<UserDashboard />} />
            <Route path="/UserTokenList" element={<UserTokenList />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </div>
    </ChakraProvider>
  );
}

export default App;
