import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { updateToken } from "../services/token-service";
import "../styles/generate.css";
import { getComapnyByCounterId } from "../services/company-services";
import { IoCall } from "react-icons/io5";
import { MdOutlineEmail } from "react-icons/md";
import { FaRegUserCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import { socket } from "../App";
import { API_URL } from "../constants/constants";
const GenerateTokenScreen = () => {
  const params = useParams();
  const id = params.id;
  console.log("preams", id);
  const navigate = useNavigate();
  const [tokenDetails, setTokenDetails] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    url: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    let valid = true;
    const newErrors = {};
    if (formData.name.trim() === "") {
      newErrors.name = "Name is required";
      valid = false;
    }
    if (formData.phone.trim() === "") {
      newErrors.phone = "Phone is required";
      valid = false;
    }
    if (formData.email.trim() === "") {
      newErrors.email = "Email is required";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const valid = validateForm();
    if (!valid) return;
    setLoading(true);
    try {
      const { data } = await updateToken(id + window.location.search, {
        ...formData,
        web: true,
        url:
          "https://turnbyturn.sensationsolutions.in" +
          window.location.pathname +
          window.location.search,
      });
      localStorage.setItem("token", JSON.stringify(data));
      setLoading(false);
      socket.emit("turn-change", {
        counterId: id.split("?")[0],
        ComapnyId: params?._id,
      });
      toast.success("Token Updated Successfully.");
      navigate("/userDashboard", { state: { userData: data } });
    } catch (error) {
      console.log("UPDATE TOKEN API ERRORdsdsd: ", error);
      toast.error(error?.response?.data.message ?? "Internal Server Error.");
      setLoading(false);
    }
  };
  // console.log("tyuiouytu",tokenDetails)
  useEffect(() => {
    const init = async () => {
      try {
        const { data } = await getComapnyByCounterId(id);
        if (data && data.length) setTokenDetails(data[0]);
      } catch (error) {
        console.log("ERROR: ", error);
      }
    };
    if (id) {
      init();
    }
  }, [id]);
  return (
    <div
      className="container"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        alignItems: "center",
        padding: "30px",
      }}
    >
      <p
        style={{
          fontWeight: "700",
          fontSize: "20px",
          lineHeight: "25px",
          marginTop: 10,
        }}
      >
        Hi, User
      </p>
      <p
        style={{
          fontWeight: "500",
          fontSize: "20px",
          lineHeight: "25px",
          color: "#6C2EF6",
          marginTop: "8px",
        }}
      >
        Create Turn Token
      </p>
      <div className="business-box">
        {tokenDetails &&
        tokenDetails?.companyId &&
        tokenDetails &&
        tokenDetails?.companyId.image ? (
          <img
            src={`${API_URL + tokenDetails?.companyId.image}`}
            style={{
              height: "58px",
              width: "58px",
              borderRadius: "50%",
              position: "absolute",
              top: "15px",
              right: "15px",
            }}
          />
        ) : (
          <FaRegUserCircle
            style={{
              height: "58px",
              width: "58px",
              borderRadius: "50%",
              position: "absolute",
              top: "15px",
              right: "15px",
              color: "#fbfbfb",
            }}
          />
        )}
        <p className="business-name">
          {tokenDetails && tokenDetails?.companyId
            ? tokenDetails?.companyId.name.length > 14
              ? `${tokenDetails?.companyId.name.substring(0, 14)}...`
              : tokenDetails?.companyId.name
            : ""}
        </p>
        <p className="business-info">
          <IoCall
            style={{ marginRight: "3px", fontWeight: "700", fontSize: "14px" }}
          />
          +91-
          {tokenDetails && tokenDetails?.companyId
            ? tokenDetails?.companyId.phone
            : ""}
        </p>
        <p className="business-info">
          <MdOutlineEmail
            style={{ marginRight: "3px", fontWeight: "700", fontSize: "14px" }}
          />

          {tokenDetails && tokenDetails?.companyId
            ? tokenDetails?.companyId.email
            : ""}
        </p>
        <p className="business-address">
          {tokenDetails && tokenDetails?.companyId
            ? tokenDetails?.companyId.address
            : ""}
        </p>
      </div>
      <form style={{ width: "100%" }} onSubmit={handleSubmit}>
        <div className="input-box">
          <p>Name</p>
          <input
            className="input"
            type="text"
            placeholder="Name"
            name="name"
            onChange={onChange}
          />
          {errors.name && (
            <p
              style={{ color: "red", marginBottom: "20px", marginTop: "-5px" }}
            >
              {errors.name}
            </p>
          )}
        </div>
        <div className="input-box">
          <p>Phone</p>
          <input
            className="input"
            type="number"
            placeholder="Phone"
            name="phone"
            value={formData.phone}
            onChange={(e) => {
              if (e.target.value.length >= 11) {
                return;
              }
              onChange(e);
            }}
          />
          {errors.phone && (
            <p
              style={{ color: "red", marginBottom: "20px", marginTop: "-5px" }}
            >
              {errors.phone}
            </p>
          )}
        </div>
        <div className="input-box">
          <p>Email</p>
          <input
            className="input"
            type="email"
            placeholder="Email"
            name="email"
            onChange={onChange}
          />
          {errors.email && (
            <p
              style={{ color: "red", marginBottom: "20px", marginTop: "-5px" }}
            >
              {errors.email}
            </p>
          )}
        </div>
        <button className="submit-btn" type="submit">
          {loading ? "Loading..." : "Submit"}
        </button>
      </form>
    </div>
  );
};
export default GenerateTokenScreen;
