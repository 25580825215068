import { API_URL } from "../constants/constants";
import httpService from "./http-service";
const tokenUrl = API_URL + "/tokens/";
const tokenQRUrl = API_URL + "/tokens/qr/";

const getCountersUrl = (companyId) => API_URL + `/counter/${companyId}`;
const getTokensByCounterIdUrl = (id) => API_URL + `/tokens/${id}`;
const getCurrentTokenUrl = (counterId) =>
  API_URL + `/tokens/get-current-token/${counterId}`;
const getUserTokenList = (counterId) => API_URL + `/tokens/user/${counterId}`;

export function getCounters(companyId) {
  return httpService.get(getCountersUrl(companyId));
}

export function getTokensByCounterId(id) {
  try {
    return httpService.get(getTokensByCounterIdUrl(id));
  } catch (error) {}
}

export function updateToken(id, payload) {
  return httpService.put(tokenUrl + id, payload);
}

export function createToken(payload) {
  try {
    return httpService.post(tokenUrl, payload);
  } catch (error) {
    console.log("Create Token API ERROR: ", error);
    return {};
  }
}

export function getToken(id) {
  return httpService.get(tokenUrl + id);
}

export function getTokensQR(token) {
  return httpService.get(tokenQRUrl + token);
}

export function getCurrentToken(counterId) {
  return httpService.get(getCurrentTokenUrl(counterId));
}

export function getUserTokenById(payload) {
  return httpService.get(getUserTokenList(payload));
}

export function getU(payload) {
  return httpService.get(getUserTokenList(payload));
}
