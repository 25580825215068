export const STATUS_ENUM = Object.freeze({
  PENDING: "pending",
  Called: 'called',
  Arrived: "arrived",
  Archieved: "archieved",
});
export const USER_ENUM = Object.freeze({
  USER: "user",
  ADMIN: 'admin',
  COMPANY: "company",
 
});