import React from "react";
const UserTokenList = () => {
  return (
    <div className="container" style={{ display: "flex", justifyContent: "center", }}>
      <div style={{display:"flex",justifyContent: "center",alignItems: "center"}}><h1 style={{fontSize:"50px",background: "beige",
    padding:" 0px 30px",
    fontWeight: "900"}}>Your Turn Is Finish Now</h1></div>
    </div>
  );
};

export default UserTokenList;
