import { API_URL } from "../constants/constants";
import httpService from "./http-service";

const AddCounter =  API_URL + `/counter`;
  export function createCounter(payload) {
    return  httpService.post(AddCounter, payload)
  }

  export function getCounter(id) {
    return httpService.get(AddCounter + "/self/" + id);
  }