import { API_URL } from "../constants/constants";
import httpService from "./http-service";

const getComapnyUrl = (userId) => API_URL + `/company/user/${userId}`;
const getComapnyById = (Id) => API_URL + `/tokens/current-token-info/${Id}`;
const companyEdit = (id) => API_URL + `/company/${id}`;
const createComapnyUrl = API_URL + `/company`;
export function getCompany(userId) {
  return httpService.get(getComapnyUrl(userId));
}
export function createCompany(payload) {
  const options = {
    // "Content-Type": "application/x-www-form-urlencoded",
  };
  return httpService.post(createComapnyUrl, payload);
}
export const getComapnyByCounterId = (id) => {
  return httpService.get(getComapnyById(id));
};
export const EditCompany = (id, payload) => {
  return httpService.patch(companyEdit(id), payload);
};
