import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { PiBellRingingBold } from "react-icons/pi";
import { updateToken } from "../../services/token-service";
import { socket } from "../../App";
import { STATUS_ENUM } from "../../constants/enum";
function CurrentTurnModal({ show, onHide, data, init, setShow }) {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    const tokenData = JSON.parse(
      localStorage.getItem("token") ?? JSON.stringify("")
    );
    setUserData(tokenData)
    if (show) {
      onOpen();
    }
  }, [show]);
  const handleStatusUpdate = async (item, status) => {
    // console.log("fghjklj ");
    try {
      // stopvibrate()
      setLoading(true);
      const response = await updateToken(item.id, { status });
      if (response.status === 200) {
        const updatedUserData = {
          ...userData,
          status: status,
        };
        setUserData(updatedUserData);
        localStorage.setItem("token", JSON.stringify(updatedUserData));
        init();
      }
      
      socket.emit("turn-change", {
        turn: item.token,
        counterId: item.counterId,
        status,
        data,
        userId: item.userId,
        id: item?._id,
      });
      onClose();
      setShow(false);
      setLoading(false);
    } catch (error) {
      console.log("modal ststus update error ", error);
      setLoading(false);
    }
  };
  // console.log("RING===", data);
  return (
    <Modal
      isOpen={show}
      onClose={() => {
        onHide(false);
        onClose();
      }}
      size="sm"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        {/* <ModalHeader></ModalHeader> */}
        <ModalCloseButton />
        <ModalBody
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "40px 20px",
          }}
        >
          <PiBellRingingBold fontSize={100} />
          <p
            style={{
              color: "green",
              // textTransform: "uppercase",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            {" "}
            {data?.item.companyId.name} 
          </p>
          <p
            style={{
              color: "green",
              textTransform: "uppercase",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            {" "}
             Is callaing You.
          </p>
          <p
            style={{
              color: "green",
              textTransform: "uppercase",
              fontSize: "35px",
              fontWeight: "600",
            }}
          >
            {" "}
            {data?.item.name}{" "}
          </p>
          
          <p
            style={{
              color: "green",
              textTransform: "uppercase",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            It's your turn now
          </p>
          <p
            style={{
              color: "green",
              textTransform: "uppercase",
              fontSize: "25px",
              fontWeight: "600",
            }}
          >
            {data.turn}
          </p>
          <p
            style={{
              fontWeight: "600",

              fontSize: "18px",
            }}
          >
            Counter Name
          </p>
          <p
            style={{
              fontWeight: "600",
              fontSize: "20px",
              textTransform: "capitalize",
              color: "green",
            }}
          >
            {data?.counterId?.name}
          </p>
<div style={{width:"100%",border:"0.3px solid gray",marginTop:"10px"}}></div>
<div style={{marginTop:5}}><p>  Please let us know once your arrive</p></div>
          <div className="modal-footer">
            <button
              className="arrive-button"
              onClick={() => handleStatusUpdate(data, STATUS_ENUM.Arrived)}
            >
              {loading ? <div className="spinner"></div> : "Arrive"}
            </button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default CurrentTurnModal;
