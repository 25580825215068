import React, { useEffect, useState } from "react";
import { FaRegUserCircle } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { MdOutlineEmail } from "react-icons/md";
import moment from "moment";
import { getCurrentToken } from "../../services/token-service";
import "../../styles/token-details.css";
import { socket } from "../../App";
import CurrentTurnModal from "./CurrentTurnModal";
import { updateToken } from "../../services/token-service";
import { STATUS_ENUM } from "../../constants/enum";
import { useNavigate } from "react-router-dom";

const UserDashboard = () => {
  const [userData, setUserData] = useState({});
  const [currentTurn, setCurrentTurn] = useState();
  const [show, setShow] = useState(false);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [button, setButton] = useState(false);
  const navigate = useNavigate();

  const handleTokenUpdate = (data) => {
    setUserData(data);
    localStorage.setItem("token", JSON.stringify(data));
  };

  const init = async () => {
    try {
      const tokenData = JSON.parse(
        localStorage.getItem("token") ?? JSON.stringify("")
      );
      setUserData(tokenData);
      const value = tokenData.counterId?._id;
      const { data } = await getCurrentToken(value);

      if (data && data.list && data.list.length) {
        setCurrentTurn(
          data.lastThreeArrivedTokens && data.lastThreeArrivedTokens.length
            ? data.lastThreeArrivedTokens[0]
            : data.currentTurn
        );
      } else
        setCurrentTurn({
          _id: 1,
          name: "",
          phone: "",
          status: "arrived",
          counterId: value,
          token: 1,
        });
    } catch (error) {}
  };
  useEffect(() => {
    init();
    function onConnect() {
      console.log("Connected....");
    }
    function onDisconnect() {
      console.log("disconnected....");
    }
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("turn-changed", (data) => {
      setData(data);
      const tokenDetail = JSON.parse(
        localStorage.getItem("token") ?? JSON.stringify({})
      );
      if (data.status === "pending" && userData?.companyId?._id === data._id) {
        const tokenData = JSON.parse(
          localStorage.getItem("token") ?? JSON.stringify("")
        );
        handleTokenUpdate({
          ...tokenData,
          status: "pending",
        });
      }
      if (
        data.status === "archieved" &&
        userData?.companyId?._id === data._id
      ) {
        navigate("/UserTokenList");
      }

      if (data.status === "called" && userData?.companyId?._id === data._id) {
        setShow(!show);
        setButton(false);
        const tokenData = JSON.parse(
          localStorage.getItem("token") ?? JSON.stringify("")
        );

        handleTokenUpdate({
          ...tokenData,
          status: "called",
        });
      }

      if (data.status === "arrived" && userData?.companyId?._id === data._id) {
        setButton(true);
      }
      if (tokenDetail.counterId?._id === data.counterId?._id) {
        if (data.status !== "archieved") setCurrentTurn(data.turn);
      }
    });
    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("turn-changed", () => {});
    };
  }, []);

  const handleStatusUpdate = async (item, status) => {
    try {
      setLoading(true);
      const response = await updateToken(item, { status });
      if (response.status === 200) {
        const updatedUserData = {
          ...userData,
          status: status,
        };
        handleTokenUpdate(updatedUserData);
        localStorage.setItem("token", JSON.stringify(updatedUserData));
        setButton(true);
      }
      socket.emit("turn-change", {
        turn: item.token,
        counterId: item.counterId,
        status,
        data,
        userId: item.userId,
        id: item,
      });
      setLoading(false);
    } catch (error) {
      console.log("modal ststus update error ", error);
      setLoading(false);
    }
  };
  const DownloadButton = () => {
    const url =
      "https://play.google.com/store/apps/details?id=ao.co.appy.saude&hl=en";
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "AppySaude.apk");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="token-detail-container">
      <div className="detail-header">
        <div
          style={{ display: "flex" }}
          className="back-icon"
          onClick={() => {}}
        >
          Token Detail
        </div>
        <div
          style={{ color: "#FFF", cursor: "pointer" }}
          onClick={() => DownloadButton()}
        >
          Download apk
        </div>
      </div>
      <div className="business-box" style={{ background: "rgba(0,0,0,0.35)" }}>
        {userData &&
        userData?.companyId &&
        userData &&
        userData?.companyId.image ? (
          <img
            src={`https://turnbyturn.api.sensationsolutions.in${userData?.companyId.image}`}
            style={{
              height: "58px",
              width: "58px",
              borderRadius: "50%",
              position: "absolute",
              top: "15px",
              right: "15px",
            }}
          />
        ) : (
          <FaRegUserCircle
            style={{
              height: "58px",
              width: "58px",
              borderRadius: "50%",
              position: "absolute",
              top: "15px",
              right: "15px",
              color: "#fbfbfb",
            }}
          />
        )}
        <p className="business-name">
          {userData && userData?.companyId
            ? userData?.companyId.name.length > 14
              ? `${userData?.companyId.name.substring(0, 14)}...`
              : userData?.companyId.name
            : ""}
        </p>
        <p className="business-info">
          <IoCall
            style={{ marginRight: "3px", fontWeight: "700", fontSize: "14px" }}
          />
          +91-
          {userData && userData?.companyId ? userData?.companyId.phone : ""}
        </p>
        <p className="business-info">
          <MdOutlineEmail
            style={{ marginRight: "3px", fontWeight: "700", fontSize: "14px" }}
          />

          {userData && userData?.companyId ? userData?.companyId.email : ""}
        </p>
        <div
          style={{
            width: "100%",
            borderBottom: "1.31px dashed rgba(126, 126, 126, 1)",
            marginBottom: "10px",
            marginTop: "5px",
          }}
        />
        <p className="business-address">
          {userData && userData?.companyId ? userData?.companyId.address : ""}
        </p>
      </div>
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "20px",
          padding: "20px 30px",
          alignItems: "center",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <div>
          <h2 className="counter-name">Counter Name</h2>
          <h1 className="counter-name-dynamic">{userData?.counterId?.name}</h1>
        </div>
        <div
          style={{
            width: "100%",
            borderBottom: "1.31px dashed rgba(126, 126, 126, 1)",
            marginBottom: "10px",
            marginTop: "5px",
          }}
        />
        <p className="current-turn-text">Current Turn is</p>
        <p
          style={{
            color: "green",
            fontWeight: "800",
            fontSize: 40,
            textAlign: "center",
          }}
        >
          {currentTurn && currentTurn?.length >= 2 ? currentTurn : currentTurn}
        </p>
        <p className="your-turn-text">Your Turn Number is</p>
        <p
          style={{
            color: "#000000",
            fontWeight: "800",
            fontSize: 40,
            textAlign: "center",
          }}
        >
          {userData?.token && userData?.token.length >= 2
            ? userData?.token
            : userData?.token}
        </p>
        {button ||
        userData?.status === "arrived" ||
        userData?.status === "requested" ? null : (
          <div className="modal-footer">
            <button
              className="arrive-button"
              onClick={() =>
                handleStatusUpdate(userData?._id, STATUS_ENUM.Arrived)
              }
            >
              {loading ? <div className="spinner"></div> : "Arrive"}
            </button>
          </div>
        )}
        {userData?.status == "requested" ? (
          <div>
            <p style={{ color: "red" }}>Admin will approve your token soon!</p>
          </div>
        ) : null}

        <div
          style={{
            width: "100%",
            borderBottom: "1.31px dashed rgba(126, 126, 126, 1)",
            marginBottom: "10px",
            marginTop: "5px",
          }}
        />
        <div
          style={{
            width: "100%",
          }}
        >
          <div className="user-info">
            <p>Name</p>
            <p>{userData?.name ?? ""}</p>
          </div>
          <div className="user-info">
            <p>Phone Number</p>
            <p>+91-{userData?.phone ?? ""}</p>
          </div>
          <div className="user-info">
            <p>Email</p>
            <p>{userData?.email ?? ""}</p>
          </div>
          <div className="user-info">
            <p>Date and Time</p>
            <p>
              {/* {moment(userData?.updatedAt).format("dd-mm-yyyy |hh:mm A")} */}
              {/* {TimeFormat(userData?.updatedAt)} */}
              {moment(userData?.updatedAt).format("DD-MM-YYYY | hh:mm A")}
            </p>
          </div>
        </div>
      </div>
      {show && (
        <CurrentTurnModal
          show={show}
          init={init}
          onHide={() => setShow(false)}
          data={data}
          setShow={setShow}
        />
      )}
    </div>
  );
};
export default UserDashboard;
